import { createSlice } from "@reduxjs/toolkit";

const layoutReducer = createSlice({
  name: "layout",
  initialState: {
    sidebarShow: "responsive",
  },
  reducers: {
    changeLayout: (state, action) => {
      switch (action.payload.type) {
        case "set":
       
          return (state = action.payload);
        default:
          return state;
      }
    },
  },
});

export const { changeLayout } = layoutReducer.actions;
export default layoutReducer.reducer;
