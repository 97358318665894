import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { enumData } from "src/service/enumData/enumData";

export const getAllBranches = createAsyncThunk("branches/getAll", async () => {
  const response = await enumData.getBranchesDataList();

  return response.data?.data?.map((item) => ({
    ...item,
    value: item.id,
    label: `${item.code} - ${item.fullName}`,
  }));
});

const branchesReducer = createSlice({
  name: "branches",
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getAllBranches.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

export default branchesReducer.reducer;
