import { BRANCHES_URL, ENUM_URL } from "src/constant/constantURL";
import { get } from "../axios";

export const enumData = {
  getEnumDataList() {
    return get(`${ENUM_URL}`);
  },
  getBranchesDataList() {
    return get(`${BRANCHES_URL}`);
  },
};
