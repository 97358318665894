import { createSlice } from "@reduxjs/toolkit";

const bgColorReducer = createSlice({
  name: "bgColor",
  initialState: {
    bgColor: localStorage.getItem("color") || "bg-gray",
  },
  reducers: {
    changeBg: (state, action) => {
      switch (action.payload.type) {
        case "SET":
          return (state = action.payload);
        default:
          return state;
      }
    },
  },
});

export const { changeBg } = bgColorReducer.actions;
export default bgColorReducer.reducer;
