import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { enumData } from "src/service/enumData/enumData";

export const getAllEnumData = createAsyncThunk(
  "enumData/getAllEnum",
  async () => {
    const response = await enumData.getEnumDataList();

    return response.data.data;
  }
);

const enumDataReducer = createSlice({
  name: "enumData",
  initialState: {
    enumData: {},
  },
  reducers: {},
  extraReducers: {
    [getAllEnumData.fulfilled]: (state, action) => {
      state.enumData = action.payload;
    },
  },
});

export default enumDataReducer.reducer;
